<template>
  <v-card
    id="recurring-order-form"
    min-height="350"
  >
    <!-- actions -->
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <recurring-order-tab-general
          :data-params="generalData"
          :tab="tab"
          :option="option"
          :weekdays="weekdayOptions"
          @changeTab="changeTab"
          @save="saveAction"
        ></recurring-order-tab-general>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiTableArrowLeft,
} from '@mdi/js'
import {
  ref, watch, onMounted, getCurrentInstance,
} from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import { createRecurringOrder, editRecurringOrder, getRecurringOrder } from '@api'
import Swal from 'sweetalert2'
import moment from 'moment'
import useSelectOptions from '@core/utils/useSelectOptions'
import useRecurringOrderList from './useRecurringOrderList'

// components
import RecurringOrderTabGeneral from './RecurringOrderTabGeneral.vue'

export default {
  components: {
    RecurringOrderTabGeneral,
  },
  setup() {
    const { t, tInContext } = useUtils()
    const { route, router } = useRouter()
    const { configOrganization, configFacility } = useSelectOptions()
    const {
      weekdayOptions,
      userData,
    } = useRecurringOrderList()
    const vm = getCurrentInstance().proxy

    const tab = ref(0)
    const option = ref(0)
    const pageData = ref()
    const filterData = ref()
    const dataId = ref(null)
    const generalData = ref({})
    const postData = ref({
      group_id: null,
      facility_id: null,
      field_id: null,
      user_id: userData.value.username,
      start_time: null,
      video_time: null,
      status: null,
      weekdays: [],
      phone_numbers: [],
    })
    const putData = ref({
      group_id: null,
      facility_id: null,
      field_id: null,
      user_id: userData.value.username,
      start_time: null,
      video_time: null,
      status: null,
      weekdays: [],
      phone_numbers: [],
    })

    // tabs
    const tabs = ref([
      {
        title: 'General',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
    ])

    const changeTab = (tabNumber, validForm) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const fetchSave = async data => {
      let resp = null
      if (option.value === 1) {
        postData.value = Object.assign(postData.value, {
          group_id: userData.value.role !== 'A' ? userData.value.group_id : configOrganization.value,
          facility_id: configFacility.value,
          field_id: data.field_id,
          user_id: userData.value.username,
          start_time: data.start_time,
          video_time: data.video_time,
          status: data.status,
          weekdays: data.weekdays,
          phone_numbers: data.phone_numbers,
        })
        resp = await createRecurringOrder(postData.value)
      }

      if (option.value === 3) {
        putData.value = Object.assign(putData.value, {
          group_id: userData.value.role !== 'A' ? userData.value.group_id : configOrganization.value,
          facility_id: configFacility.value,
          field_id: data.field_id,
          user_id: userData.value.username,
          start_time: data.start_time,
          video_time: data.video_time,
          status: data.status,
          weekdays: data.weekdays,
          phone_numbers: data.phone_numbers,
        })
        resp = await editRecurringOrder(putData.value, data.id)
      }

      if (resp.ok) {
        router.go(-1)
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    const saveAction = async data => {
      if (!option.value) {
        router.go(-1)
      }

      const titleDate = new Date()
      titleDate.setDate(titleDate.getDate() + 6)
      const formatDate = moment(titleDate.toISOString().substring(0, 10)).format('dddd, MMMM Do')
      const customTitle = `${tInContext('videos.orders_till', vm)} ${formatDate} ${tInContext('videos.will_be_created', vm)}`
      const confirmText = option.value === 1 ? `${tInContext('videos.create_recurring', vm)}` : `${tInContext('videos.edit_recurring', vm)}`

      if (data.status === 'A') {
        Swal.fire({
          title: customTitle,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: confirmText,
          cancelButtonText: 'No',
        }).then(async result => {
          if (result.isConfirmed) await fetchSave(data)
        })
      } else {
        await fetchSave(data)
      }
    }

    const cancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      tabs.value[2].disabled = true
      generalData.value = {}
    }

    const goBack = () => {
      // name: 'views-recurring-order-list',
      router.replace({
        name: 'views-video-view',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      option.value = route.value.params.option || 2
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams

      dataId.value = route.value.params.id
      if (dataId.value) {
        const resp = await getRecurringOrder(dataId.value)
        if (resp.ok) {
          generalData.value = resp.data
          if (option.value !== 1) {
            tabs.value.forEach(e => {
              e.disabled = false
            })

            tabs.value.splice(1, 1)
          }
        } else goBack()
      } else option.value = 1
    })

    return {
      tab,
      tabs,
      userData,
      pageData,
      filterData,
      option,
      dataId,
      generalData,
      postData,
      putData,
      weekdayOptions,

      changeTab,
      saveAction,
      cancelAction,
      goBack,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
